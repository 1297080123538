import axios from 'axios'
import bus from '../libs/bus'
import { events, TOKEN, VERTICAL } from '../constants'
import { env } from './env'

const accountsURLs = {
  prod: "https://api.accounts.estrategiaeducacional.com.br",
  sandbox: "https://accounts.bowie.estrategia.ws",
  qa: "https://accounts.bowie.estrategia.qa",
  dev: "https://accounts.bowie.estrategia.ws",
  local: "https://accounts.bowie.estrategia.ws"
}

const castURLs = {
  prod: "https://api.estrategia.com/cast",
  sandbox: "https://api.estrategia-sandbox.com.br/cast",
  qa: "https://api.estrategia-qa.com.br/cast",
  dev: "https://cast.tnt.estrategia.dev",
  local: "http://localhost:4007"
}

const catalogsURLs = { 
  prod: "https://api.estrategia.com/catalogs",
  sandbox: "https://api.estrategia-sandbox.com.br/catalogs",
  qa: "https://api.estrategia-qa.com.br/catalogs",
  dev: "https://api.estrategia-sandbox.com.br/catalogs",
  local: "https://api.estrategia-sandbox.com.br/catalogs"
}

const togglerURLs = {
  prod: "https://api.toggler.estrategia.io",
  sandbox: "https://api.toggler.estrategia.ws",
  qa: "https://api.toggler.estrategia.qa",
  dev: "https://api.toggler.estrategia.ws",
  local: "https://api.toggler.estrategia.ws"
}

const interceptorErrorHandler = error => {
  bus.publish(events.REQUEST_ERROR)
  return Promise.reject(error)
}

const createAxiosInstance = (API_URL) => {
  const instance = axios.create({
    baseURL: API_URL,
    timeout: 30000,
    headers: {
      'Content-Type': 'application/json'
    }
  })

  instance.interceptors.request.use(config => {
    const token = localStorage.getItem(TOKEN) || env.TOKEN
    config.headers.Authorization = `Bearer ${token}`
    config.headers['X-Vertical'] = sessionStorage.getItem(VERTICAL) || 'concursos'
    config.headers['X-Requester-ID'] = "bo-cast"
    return config
  }, interceptorErrorHandler)

  instance.interceptors.response.use(undefined, interceptorErrorHandler)
  return instance
}
const castURL = castURLs[env.API_ENV] + "/api"
const accountsURL = accountsURLs[env.API_ENV]
const catalogsURL = catalogsURLs[env.API_ENV]
const togglerURL = togglerURLs[env.API_ENV]


const cast = createAxiosInstance(castURL)
const accounts = createAxiosInstance(accountsURL)
const catalog = createAxiosInstance(catalogsURL)
const toggler = createAxiosInstance(togglerURL)

const requireFile = require.context('../services', false, /[\w-]+\.js$/)

const services = {}
requireFile.keys().forEach(fileName => {
  const config = requireFile(fileName)
  const name = fileName
    .replace(/^\.\//, '')
    .replace(/^\.\/_/, '')
    .replace(/\.\w+$/, '')
  const Service = config.default || config
  services[name] = new Service({ axios: cast, accounts: accounts, catalog: catalog, toggler: toggler, vertical: sessionStorage.getItem(VERTICAL) || 'concursos' })
})

const setToken = token => {
  [cast].map(instance => {
    instance.defaults.headers.common['Authorization'] = `Bearer ${token}`
  })
}

const boot = ({ Vue }) => {
  Vue.prototype.$s = services
}

export { boot as default, setToken }
