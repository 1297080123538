import Vue from 'vue'
import VueRouter from 'vue-router'

import routes from './routes'

Vue.use(VueRouter)

/*
 * If not building with SSR mode, you can
 * directly export the Router instantiation
 */

  const Router = new VueRouter({
    // scrollBehavior: () => ({ x: 0, y: 0 }),
    routes,

  })

  export default Router
