export default class Album {
  constructor (deps) {
    this.$http = deps.axios
  }

  async fetchAlbums (search) {
    const resp = await this.$http.get(`/internal/albums${search}`)
    return resp.data
  }

  async fetchAlbumsPublished (search) {
    const resp = await this.$http.get(`/internal/albums/published${search}`)
    return resp.data
  }

  async fetchAlbum (id) {
    const resp = await this.$http.get(`/internal/albums/${id}`)
    return resp.data
  }

  async createAlbum (data) {
    const resp = await this.$http.post(`/internal/albums`, data)
    return resp.data
  }

  async updateAlbum (id, data) {
    const resp = await this.$http.put(`/internal/albums/${id}`, data)
    return resp.data
  }

  async deleteAlbum (id) {
    const resp = await this.$http.delete(`/internal/albums/${id}`)
    return resp.data
  }

  async publishAlbum (id) {
    const resp = await this.$http.put(`/internal/albums/publish/${id}`)
    return resp.data
  }

  async unpublishAlbum (id) {
    const resp = await this.$http.put(`/internal/albums/unpublish/${id}`)
    return resp.data
  }
}
