import Vue from 'vue'
import App from './App.vue'
import router from './router'

import bootServices from './boot/services.js'
import bootEnv from './boot/env.js'
import bootBus from './boot/bus.js'
import bootBridger from './boot/bridger'
// import bootComponents from './boot/components'

import '@estrategiahq/coruja-web-ui/dist/coruja-web-ui.css';
import '@estrategiahq/coruja-web-ui/dist/coruja-tailwind.css'; 
import { CToast } from '@estrategiahq/coruja-web-ui';

Vue.use(CToast);

Vue.config.productionTip = false

bootEnv({Vue})
bootServices({Vue})
bootBus({Vue})
bootBridger({Vue,router})
// bootComponents({Vue})

Vue.filter('dateFormat', function (value) {
  if (!value || !Date.parse(value) ) return ''

  return Intl.DateTimeFormat("pt-br", { dateStyle: "short" }).format(Date.parse(value))

})

new Vue({
  router:router,
  render: h => h(App)
}).$mount('#app')
