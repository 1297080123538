export const events = {
  LOADING_START: 'loading_start',
  LOADING_STOP: 'loading_stop',
  REQUEST_ERROR: 'request_error',
  DIALOG_ERROR: 'dialog_error_visible',
  SHOW_TOAST: 'show_toast'
}

export const TOKEN = 'token'
export const VERTICAL = 'vertical'

export default {
  events,
  TOKEN
}
