
const routes = [
  {
    path: '/',
    component: () => import('../layouts/Layout.vue'),
    children: [
      { path: 'albuns', name: 'albums', component: () => import('../pages/Albums/List.vue') },
      { path: 'albuns/novo', name: 'createAlbum', component: () => import('../pages/Albums/Edit.vue') },
      { path: 'albuns/editar/:id', name: 'editAlbum', component: () => import('../pages/Albums/Edit.vue') },
      { path: 'faixas', name: 'tracks', component: () => import('../pages/Tracks/List.vue') },
      { path: 'faixas/novo', name: 'createTrack', component: () => import('../pages/Tracks/Edit.vue') },
      { path: 'faixas/editar/:id', name: 'editTrack', component: () => import('../pages/Tracks/Edit.vue') },
      { path: 'estantes', name: 'shelves', component: () => import('../pages/Shelves/List.vue') },
      { path: 'estantes/editar/:id', name: 'editShelf', component: () => import('../pages/Shelves/Edit.vue') },
      { path: 'estantes/novo', name: 'createShelf', component: () => import('../pages/Shelves/Edit.vue') },
      { path: 'home-audio', name: 'home', component: () => import('../pages/Home/Home.vue') },
    ]
  }
]


// Always leave this as last one
if (process.env.MODE !== 'ssr') {
  routes.push({
    path: '*',
    component: () => import('../pages/Error404.vue')
  })
}

export default routes
