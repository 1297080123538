export default class Home {
  constructor (deps) {
    this.$http = deps.axios
  }

  async fetchHome () {
    const resp = await this.$http.get('/internal/home')
    return resp.data
  }

  async saveHome (data) {
    const resp = await this.$http.post('/internal/preference/home', data)
    return resp.data
  }
}
