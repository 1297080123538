export default class Track {
  constructor (deps) {
    this.$http = deps.axios
  }

  async fetchTracks (search) {
    if (!search) search = ''
    const resp = await this.$http.get(`/internal/tracks${search}`)
    return resp.data
  }

  async fetchTrack (id) {
    const resp = await this.$http.get(`/internal/tracks/${id}`)
    return resp.data
  }

  async fetchTracksFromTeacher (id) {
    const resp = await this.$http.get(`/internal/tracks/teacher/${id}`)
    return resp.data
  }

  async getTrackUrl (id) {
    const resp = await this.$http.get(`/internal/tracks/url/${id}`)
    return resp.data
  }

  async createTrack (data) {
    const resp = await this.$http.post(`/internal/tracks`, data)
    return resp.data
  }

  async updateTrack (id, data) {
    const resp = await this.$http.put(`/internal/tracks/${id}`, data)
    return resp.data
  }

  async deleteTrack (id) {
    const resp = await this.$http.delete(`/internal/tracks/${id}`)
    return resp.data
  }
}
