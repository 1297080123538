export default class Catalog {
  constructor (deps) {
    this.$http = deps.catalog
  }

  async fetchCategories (id,search) {
    const resp = await this.$http.get(`/categories/${id}${search}`)
    return resp.data
  }

}
