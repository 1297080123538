import { Bridger } from 'frontend-libs/src/bridger'
import { TOKEN, VERTICAL } from '../constants'

const bridger = Bridger().Consumer()

export {
  bridger
}

export default async ({ Vue, router }) => {
  Vue.prototype.$bridger = bridger
  router.afterEach((to) => {
    Bridger().Consumer().$dispatch({
      type: 'router_navigation:after',
      payload: {
        fullPath: to.fullPath
      }
    })
  })

  if (window.self !== window.top) { // iframed
    return new Promise((resolve) => {
      bridger.$subscribe('event:user-login', (payload) => {
        localStorage.setItem(TOKEN, payload.token)
        resolve()
      })
      bridger.$subscribe('event:vertical-change', (payload) => {
        const currentVertical = sessionStorage.getItem(VERTICAL) || 'concursos'
        sessionStorage.setItem(VERTICAL, payload)

        if (currentVertical !== payload) {
          location.reload()
        }

        resolve()
      })
    })
  }
}
