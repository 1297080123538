export default class Shelf {
  constructor (deps) {
    this.$http = deps.axios
  }

  async fetchShelves (search) {
    const resp = await this.$http.get(`/internal/shelves${search}`)
    return resp.data
  }

  async fetchShelf (id) {
    const resp = await this.$http.get(`/internal/shelves/${id}`)
    return resp.data
  }

  async searchShelf (term) {
    const resp = await this.$http.get(`/internal/albums?search="(title[icontains]:'${term}')"`)
    return resp.data
  }

  async createShelf (data) {
    const resp = await this.$http.post(`/internal/shelves`, data)
    return resp.data
  }

  async updateShelf (id, data) {
    const resp = await this.$http.put(`/internal/shelves/${id}`, data)
    return resp.data
  }

  async deleteShelf (id) {
    const resp = await this.$http.delete(`/internal/shelves/${id}`)
    return resp.data
  }

  async fetchShelvesWithPublishedAlbums (search) {
    const resp = await this.$http.get(`/internal/shelves/published/albums${search}`)
    return resp.data
  }
}
