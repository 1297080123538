export default class Accounts {
  constructor (deps) {
    this.$http = deps.accounts
  }

  async fetchGroups (search) {
    const resp = await this.$http.get(`/groups${search}`)
    return resp.data
  }

  async fetchUsers (search) {
    const resp = await this.$http.get(`/user${search}`)
    return resp.data
  }
}
