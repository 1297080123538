import axios from 'axios'
export default class Presigned {
  constructor (deps) {

    this.$http = deps.axios
  }

  async getPresignedURL (type, product) {
    const resp = await this.$http.get(`?type=${type}&application=${product}`)
    return resp.data
  }
  async getPresignedImageURL () {
    const resp = await this.$http.get(`/internal/upload/image`)
    return resp.data.data
  }
  async getPresignedAudioURL () {
    const resp = await this.$http.get(`/internal/upload/audio`)
    return resp.data.data
  }
  async uploadFile (url, file, type, onUploadProgress) {
    const headerMap = {
      'audio': 'audio/mp3',
      'image': 'image/jpeg'
    }
    const resp = await axios.put(
      url,
      file,
      { 
        'headers': { 'content-type': headerMap[type] } ,
        'onUploadProgress':(evt)=>{
          onUploadProgress(evt)
        },
      })
    return resp
  }
}
